// ContactUs.js
import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
      <p className="mb-4">
        We would love to hear from you! If you have any questions, feedback, or concerns, please reach out to us using the following contact information:
      </p>
      <p className="mb-2">
        <strong>Email:</strong> info@tennisindialive.com
      </p>
     
    </div>
  );
};

export default ContactUs;
