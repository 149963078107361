import React from 'react';

const TermsOfService = () => {
    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p className="text-sm mb-4">
                Welcome to <strong>tennisindialive.com</strong>. By accessing and using our website, you agree to comply with the following terms and conditions. Please read them carefully.
            </p>

            <h2 className="text-xl font-semibold mb-2">1. Use of Content</h2>
            <p className="text-sm mb-4">
                All content provided on this website, including scores, results, and rankings, is for informational purposes only. We do not guarantee the accuracy or completeness of this information and are not liable for any errors or omissions.
            </p>

            <h2 className="text-xl font-semibold mb-2">2. No User Data Collection</h2>
            <p className="text-sm mb-4">
                We respect your privacy. <strong>tennisindialive.com</strong> does not collect, store, or share any personal data or information from users. We are committed to providing you with a secure browsing experience without the need for registration or data submission.
            </p>

            <h2 className="text-xl font-semibold mb-2">3. Third-Party Links</h2>
            <p className="text-sm mb-4">
                Our website may contain links to third-party websites or services that are not owned or controlled by <strong>tennisindialive.com</strong>. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.
            </p>

            <h2 className="text-xl font-semibold mb-2">4. Limitation of Liability</h2>
            <p className="text-sm mb-4">
                In no event shall <strong>tennisindialive.com</strong> be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your access to or use of our website.
            </p>

            <h2 className="text-xl font-semibold mb-2">5. Changes to the Terms</h2>
            <p className="text-sm mb-4">
                We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of the new terms.
            </p>

            <h2 className="text-xl font-semibold mb-2">6. Contact Us</h2>
            <p className="text-sm mb-4">
                If you have any questions or concerns about these Terms of Service, please feel free to contact us on - info@tennisindialive.com
            </p>
        </div>
    );
};

export default TermsOfService;
