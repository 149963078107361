import React from 'react';

const AboutUs = () => {
    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">About Us</h1>
            <p className="text-sm mb-4">
                Welcome to <strong>tennisindialive.com</strong>, your go-to platform for the latest scores and results of tennis matches, categorized by country. Whether you're a fan of Indian tennis or international tournaments, we've got you covered with real-time updates.
            </p>
            <p className="text-sm mb-4">
                Our mission is to provide tennis enthusiasts with accurate and up-to-date information on their favorite players and matches. We also offer the latest ATP and WTA rankings, ensuring you never miss out on the performance of your favorite athletes.
            </p>
            <p className="text-sm mb-4">
                At <strong>tennisindialive.com</strong>, we are passionate about tennis and strive to make it easy for fans to follow the sport they love. Stay tuned for live scores, rankings, and more exciting features!
            </p>
        </div>
    );
};

export default AboutUs;
